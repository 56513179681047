import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import Img from "gatsby-image"
import './news.sass'

class References extends React.Component {
  render () {
    const newsItems = this.props.data.allPrismicNewsItem.edges;
    const metaTitle = "The latest esports news - esportstips.net"
    const metaDescription = "Keep yourself informed with the esports scene by following our high quality esports news about various topics and events. Never miss crucial pieces of information by following the Esportstips newsfeed."
    return (
      <Layout>
        <div className="index-container">
        <Helmet>
                <title>{`${metaTitle}`}</title>
                <meta name="description" content={metaDescription} />
                <meta name="image" content={config.siteLogo} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta property="og:url" content={this.props.location.href} />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:image" content={config.siteLogo} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ""}/>
                <meta name="twitter:title" content={metaTitle} />
                <meta name="twitter:description" content={metaDescription} />
                <meta name="twitter:image" content={config.siteLogo} />
                {/* <script type="application/ld+json" id="website-json-ld">
                  {JSON.stringify(
                    {
                      "@context":"http://schema.org",
                      "@type":"WebSite",
                      "name":"IKIUM",
                      "alternateName":"IKIUM - State of the art IT solutions.",
                      "url":"https://ikium.net",
                      "@context":"http://schema.org",
                      "@type":"Organization",
                      "name":"IKIUM",
                      "url":"https://ikium.net",
                      "logo":config.siteLogo,
                    }
                  )}
                </script> */}
            </Helmet>
          {/* <Hero/> */}
          <section className="section">
            <div className="container">
            <h1 className="title">Latest esports news</h1>
              <div className="columns is-multiline">
                  {
                  newsItems.map((item, index) => (
                      <div key={index} className="column is-one-third">
                        <Link to={'/news/' + item.node.uid}>
                          <div className="box news-item-box">
                            <Img fluid={ item.node.data.image.localFile.childImageSharp.fluid } alt={ item.node.uid }
                            style={{position: 'absolute', left: '0', top: '0', width: '100%', height: '100%'}}/>
                            <div className="bg-tint-4"></div>
                            <span className="tag is-primary news-key-tag">{ item.node.data.key_tag } - { item.node.data.date }</span>
                            <h2 className="news-card-title subtitle">{ item.node.data.title.text }</h2>
                            <span className="news-card-title"></span>
                            {/* <div className="overlay"></div> */}
                          </div>
                        </Link>
                      </div>
                  ))}
              </div>
            </div>
          </section>
          <section className="section">
            <div className="container">
              <h2 className="subtitle">Keep yourself up to date on esports and esports betting</h2>
              <p>Esportstips covers the latest and most relevant esports new to keep you up to date on
                esports. We cover the most interesting events in the scene that help you gather information about the
                esports scene to help you decide where to place those winning bets. Our news will help you in esports betting.
                It is very important to keep yourself informed about whats going on in the esports betting scene. Betting on esports
                matches and games online is nearly impossible without the proper knowledge and information to place to winning bets.
                Esports news are also very relevant for finding odds that you think are in your favor. We publish information
                about the best esports betting bonuses and tips so you can focus on betting on esports.</p>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default References;

export const pageQuery = graphql`
  query NewsQuery {
    allPrismicNewsItem (
      limit: 10
    	sort: {
      	fields: [data___date]
      	order: DESC
  		}) {
        edges {
          node {
            uid
            data {
              title {
                text
              }
              key_tag
              date
              description {
                html
              }
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`;
